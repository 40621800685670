/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Theme overrides
$primary-blue: #007bff;
$primary-orange: #ff6600;

// Toolbars
.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #007bff;
}

// Buttons
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #007bff;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #007bff;
  --mat-text-button-state-layer-color: #007bff;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #007bff;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

// Tabs
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #007bff;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #007bff;
  --mat-tab-header-active-ripple-color: #007bff;
  --mat-tab-header-inactive-ripple-color: #007bff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #007bff;
  --mat-tab-header-active-hover-label-text-color: #007bff;
  --mat-tab-header-active-focus-indicator-color: #007bff;
  --mat-tab-header-active-hover-indicator-color: #007bff;
}

// Stepper
html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #007bff;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #007bff;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #007bff;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
